import React from 'react';
import ResultsTable from './resultsTable';

class ResultsPage extends React.Component {

    render() {
        return (
            <div>
                <ResultsTable/>
            </div>
        );
    }
}

export default ResultsPage;