// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableContainer {
    max-height: 70vh;
  }
  
  /* TableHead */
  .TableHead {
    background-color: #f4f4f4;
  }`, "",{"version":3,"sources":["webpack://./src/css/scenario-page.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA,cAAc;EACd;IACE,yBAAyB;EAC3B","sourcesContent":[".TableContainer {\n    max-height: 70vh;\n  }\n  \n  /* TableHead */\n  .TableHead {\n    background-color: #f4f4f4;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
