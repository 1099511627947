// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itm-app .navbar {
    background-color: rgba(89, 38, 16, 1) !important;
    background-size: cover;
    padding: 0px 10px;
}

.itm-app .itm-navbar .nav-link, .itm-app .itm-navbar .nav-link-home {
    color: #ffffff !important;
    text-decoration: none;
    margin-left: 8px;
    font-size: 16px;
    margin-right: 10px;
}

.nav-brand-itm {
    width: 45px;
    height: 45px;
}

.navbar-brand {
    font-family: helvetica, sans-serif;
    font-size: 20px;
    color:  #fff !important;
    margin-left: 20px !important;
    margin-right: 50px !important;
}

.itm-app .itm-navbar .nav-login-icon {
    filter: invert(1);
}`, "",{"version":3,"sources":["webpack://./src/css/navbar.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,uBAAuB;IACvB,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".itm-app .navbar {\n    background-color: rgba(89, 38, 16, 1) !important;\n    background-size: cover;\n    padding: 0px 10px;\n}\n\n.itm-app .itm-navbar .nav-link, .itm-app .itm-navbar .nav-link-home {\n    color: #ffffff !important;\n    text-decoration: none;\n    margin-left: 8px;\n    font-size: 16px;\n    margin-right: 10px;\n}\n\n.nav-brand-itm {\n    width: 45px;\n    height: 45px;\n}\n\n.navbar-brand {\n    font-family: helvetica, sans-serif;\n    font-size: 20px;\n    color:  #fff !important;\n    margin-left: 20px !important;\n    margin-right: 50px !important;\n}\n\n.itm-app .itm-navbar .nav-login-icon {\n    filter: invert(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
