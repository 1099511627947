// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-account-container {
    display: flex;
    flex-direction: column;
    padding: 10px 25px 0 25px;
}

.my-account-container * {
    margin-bottom: 5px;
}

.my-account-description {
    margin-bottom: 15px;
}

.my-account-container input {
    width: 200px;
}

.my-account-update-password {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.my-account-update-password * {
    margin-right: 5px;
}

.pre-line {
    white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/css/my-account.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".my-account-container {\n    display: flex;\n    flex-direction: column;\n    padding: 10px 25px 0 25px;\n}\n\n.my-account-container * {\n    margin-bottom: 5px;\n}\n\n.my-account-description {\n    margin-bottom: 15px;\n}\n\n.my-account-container input {\n    width: 200px;\n}\n\n.my-account-update-password {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n}\n\n.my-account-update-password * {\n    margin-right: 5px;\n}\n\n.pre-line {\n    white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
