// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container {
    display: flex;
    flex-direction: column;
    padding: 10px 25px 0 25px;
}

.admin-container * {
    margin-bottom: 5px;
}

.admin-description {
    margin-bottom: 15px;
}

.modify-admins-header {
    margin: 15px 0 5px 0;
}

.dual-input-container {
    display: flex;
}

.dual-input-container .rdl-control-container {
    width: 400px;
    height: 250px;
}

.dual-input-container .rdl-move-all {
    visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/css/admin-page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".admin-container {\n    display: flex;\n    flex-direction: column;\n    padding: 10px 25px 0 25px;\n}\n\n.admin-container * {\n    margin-bottom: 5px;\n}\n\n.admin-description {\n    margin-bottom: 15px;\n}\n\n.modify-admins-header {\n    margin: 15px 0 5px 0;\n}\n\n.dual-input-container {\n    display: flex;\n}\n\n.dual-input-container .rdl-control-container {\n    width: 400px;\n    height: 250px;\n}\n\n.dual-input-container .rdl-move-all {\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
